import { useEffect, useRef } from 'react';

export function useAnimationFrame(callback: () => any, running = true) {
  const savedCallback = useRef(() => undefined);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    if (!running) return undefined;

    let requestId = 0;
    function tick() {
      savedCallback.current();
      requestId = window.requestAnimationFrame(tick);
    }
    requestId = window.requestAnimationFrame(tick);

    return () => window.cancelAnimationFrame(requestId);
  }, [running]);
}
