import useResponsive from 'hooks/useResponsive'
import Info from './components/Info'
import StakeHistory from './components/StakeHistory'
import StakingPool from './components/StakingPool'
import WeeklyRank from './components/WeeklyRank'
import StakingPoolNotRegister from './components/StakingPoolNotRegister'
import useNameServiceContract from './hooks/useNameServiceContract'
import { PoseStakingContext } from './context'
import useInvitationCenterContract from './hooks/useInvitationCenterContract'
import usePosePoolContract from './hooks/usePosePoolContract'

const PoseStaking = () => {
  const { isMobile } = useResponsive()
  const { listSingleEntries } = useNameServiceContract()
  const { isRegistered, getRootInviter, getInviterRecords, getInviteeRecordsLength } = useInvitationCenterContract(
    listSingleEntries && listSingleEntries.InvitationCenter.address,
  )
  const { person } = usePosePoolContract(listSingleEntries && listSingleEntries.PosePool.address)
  const rootInviterCode = getRootInviter()
  const inviterCode = getInviterRecords()
  const inviteeRecordsLength = getInviteeRecordsLength()

  return (
    <PoseStakingContext.Provider
      value={{ listSingleEntries, person, rootInviterCode, inviterCode, inviteeRecordsLength }}
    >
      <div style={wrapper}>
        <div style={isMobile ? layoutMobile : layout}>
          {/* <Info /> */}
          {isRegistered ? <StakingPool /> : <StakingPoolNotRegister />}
          {isRegistered && <StakeHistory />}
          {isRegistered && <WeeklyRank />}
        </div>
      </div>
    </PoseStakingContext.Provider>
  )
}

export default PoseStaking

const wrapper: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
}
const layout: React.CSSProperties = {
  padding: 70,
  paddingTop: 100,
  justifyContent: 'center',
  maxWidth: 1300,
  width: '100%',
}
const layoutMobile: React.CSSProperties = {
  padding: 20,
  paddingTop: 20,
  justifyContent: 'left',
}
