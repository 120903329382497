import { ethers } from 'ethers'
import { useTranslation } from '@pancakeswap/localization'
import Image from 'next/image'
import { Button, useModal, useToast } from "@pancakeswap/uikit";
import useResponsive from 'hooks/useResponsive'
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { PoseStakingContext } from 'views/PoseStaking/context'
import usePosePoolContract from 'views/PoseStaking/hooks/usePosePoolContract'
import { formatBigNumber } from '@pancakeswap/utils/formatBalance'
import { useBlockNumber } from "wagmi";
import { parseEther } from "@ethersproject/units";
import Countdown from "react-countdown";
import { formatBytes32String } from "@ethersproject/strings";
import StakingPoolImage from '../../pngs/staking_pool_image.png'
import usePoseBalance from "../../hooks/usePoseBalance";

const StakingPoolNotRegister = () => {
  const { t } = useTranslation()
  const { isMobile } = useResponsive()
  const { listSingleEntries } = useContext(PoseStakingContext)
  const { balance, getPoseAllowance, ngBalance, getPoseNgAllowance } = usePoseBalance()
  const { apy, config, stakeNg, approve, approvePoseNg, stakeNgLoading, approveLoading } = usePosePoolContract(
    listSingleEntries && listSingleEntries.PosePool.address,
  )
  const [referralCode, setReferralCode] = useState<string>('')

  const defaultReferralCode = "2LSZHUV6"

  const handleReferralCode = (event: ChangeEvent<HTMLInputElement>) => {
    setReferralCode(event.target.value)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setReferralCode(urlParams.get('referralCode') ?? '')
  }, [])

  const [amount, setAmount] = useState<number>(0)
  const [isOldEnoughAllowance, setIsOldEnoughAllowance] = useState<boolean>(false)
  const [isNgEnoughAllowance, setIsNgEnoughAllowance] = useState<boolean>(false)
  const { data: blockNumber } = useBlockNumber({ watch: true })
  const { toastError } = useToast()

  const insufficientOldBalance = useMemo(() => balance?.lt(parseEther(amount.toString())), [balance, amount])
  const insufficientNgBalance = useMemo(() => ngBalance?.lt(parseEther(amount.toString())), [ngBalance, amount])

  useEffect(() => {
    if (listSingleEntries) {
      const checkAllow = async () => {
        const allowance = await getPoseAllowance(listSingleEntries.PosePool.address)
        const ngAllowance = await getPoseNgAllowance(listSingleEntries.PosePool.address)
        setIsOldEnoughAllowance(allowance.gte(parseEther(amount.toString())))
        setIsNgEnoughAllowance(ngAllowance.gte(parseEther(amount.toString())))
      }
      checkAllow()
    }
  }, [getPoseAllowance, getPoseNgAllowance, listSingleEntries, amount, blockNumber])

  const renderOldBtnTitle = useMemo(() => {
    if (!isOldEnoughAllowance) {
      return t('Approve(legacy)')
    }
    if (config?.startTime && config.startTime.mul(1000).gt(Date.now())) {
      return <Countdown date={config.startTime.mul(1000).toNumber()} daysInHours />
    }
    return t('Stake(legacy)')
  }, [isOldEnoughAllowance, t, config])

  const renderNgBtnTitle = useMemo(() => {
    if (!isNgEnoughAllowance) {
      return t('Approve(pose)')
    }
    if (config?.startTime && config.startTime.mul(1000).gt(Date.now())) {
      return <Countdown date={config.startTime.mul(1000).toNumber()} daysInHours />
    }
    return t('Stake(pose)')
  }, [isNgEnoughAllowance, t, config])

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value || Number(event.target.value) < 0) {
      setAmount(0)
      return
    }
    setAmount(Number(event.target.value))
  }

  const onClickStake = async () => {
    if (!(listSingleEntries && stakeNg && approve && config)) {
      return
    }
    if (!isOldEnoughAllowance) {
      await approve()
      return
    }
    if (insufficientOldBalance) {
      toastError(t('Insufficient legacy Balance'))
      return
    }
    if (!amount) {
      toastError(t('Please enter amount'))
      return
    }
    // if (!referralCode) {
    //   toastError(t('Please enter referral code'))
    //   return
    // }
    await stakeNg(parseEther(amount.toString()), ethers.constants.Zero, formatBytes32String(referralCode || defaultReferralCode))
  }

  const onClickStakeNg = async () => {
    if (!(listSingleEntries && stakeNg && approvePoseNg && config)) {
      return
    }
    if (!isNgEnoughAllowance) {
      await approvePoseNg()
      return
    }
    if (config.startTime.mul(1000).gt(Date.now())) {
      toastError(t('Staking pool is not open yet'))
      return
    }
    if (insufficientNgBalance) {
      toastError(t('Insufficient POSE Balance'))
      return
    }
    if (!amount) {
      toastError(t('Please enter amount'))
      return
    }
    // if (!referralCode) {
    //   toastError(t('Please enter referral code'))
    //   return
    // }
    await stakeNg(ethers.constants.Zero, parseEther(amount.toString()), formatBytes32String(referralCode || defaultReferralCode))
  }

  return (
    <div style={isMobile ? layoutMobile : layout}>
      <div
        style={{
          flexDirection: isMobile ? 'column' : 'row',
          display: 'flex',
        }}
      >
        <div style={isMobile ? left1Mobile : left1}>
          <p style={textStyle}>{t('Stake your POSE to earn')}</p>
        </div>
        <div style={isMobile ? right1Mobile : right1}>
          <Image src={StakingPoolImage} alt="staking-pool" />
          <p style={isMobile ? titleMobile : title}>{t('Staking Pool')}</p>
          {isMobile ? (
            <div style={boxMobile}>
              <div style={itemInPool}>
                <p style={normalText}>{t('APY')}</p>
                <p style={activeText}>{apy ? `${formatBigNumber(apy, 2)}%` : '-'}</p>
              </div>
            </div>
          ) : (
            <>
              <div style={itemInPool}>
                <p style={normalText}>{t('APY')}</p>
                <p style={activeText}>{apy ? `${formatBigNumber(apy, 2)}%` : '-'}</p>
              </div>
            </>
          )}
        </div>
      </div>
      <div style={isMobile ? flexMobile : flex}>
        <div style={isMobile ? left2Mobile : left2}>
          <div style={isMobile ? itemInPoolMobile : itemInPool}>
            <p
              style={{
                width: 'fit-content',
              }}
            >
              {t('Referral Code')}
            </p>
          </div>
          <input
            name="referralCode"
            type="text"
            value={referralCode}
            onChange={handleReferralCode}
            style={isMobile ? inputStyleMobile : inputStyle}
            placeholder={t('(Optional)')}
          />
        </div>

        <div style={itemInPool}>
          <p style={normalText}>{t('Legacy Wallet')}</p>
          <p style={activeText}>{formatBigNumber(balance || ethers.constants.Zero, 2)}</p>
        </div>
        <div style={itemInPool}>
          <p style={normalText}>{t('Pose Wallet')}</p>
          <p style={activeText}>{formatBigNumber(ngBalance || ethers.constants.Zero, 2)}</p>
        </div>
        <input
          name="amount"
          type="number"
          value={amount.toString()}
          onChange={handleAmountChange}
          style={isMobile ? inputStyleMobile : inputStyle}
          placeholder={t('Type the amount')}
        />
        <div style={{display: 'flex', flexDirection: 'column', gap: 4, justifyContent: 'center', alignItems: 'center'}}>
          <Button
            isLoading={stakeNgLoading || approveLoading}
            disabled={stakeNgLoading || approveLoading}
            onClick={onClickStake}
            width="120px"
            variant="success"
            scale="sm"
            height="28px"
            minHeight={28}
          >
            {renderOldBtnTitle}
          </Button>
          <Button
            isLoading={stakeNgLoading || approveLoading}
            disabled={stakeNgLoading || approveLoading}
            onClick={onClickStakeNg}
            width="120px"
            variant="success"
            scale="sm"
            height="28px"
            minHeight={28}
          >
            {renderNgBtnTitle}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default StakingPoolNotRegister


const layout: React.CSSProperties = {
  // marginTop: 70,
  display: 'flex',
  border: '1px solid #15D4B1',
  padding: '30px 30px',
  flexDirection: 'column',
  gap: 30,
}
const layoutMobile: React.CSSProperties = {
  marginTop: 20,
  display: 'flex',
  border: '1px solid #15D4B1',
  padding: '30px 60px',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
}
const itemInPool: React.CSSProperties = {
  lineHeight: 1.8,
  textAlign: 'center',
}
const itemInPoolMobile: React.CSSProperties = {
  lineHeight: 1.8,
  display: 'flex',
  justifyContent: 'space-between',
  gap: 30,
  width: '100%',
}
const normalText: React.CSSProperties = { fontSize: 18, fontWeight: 400 }
const boxMobile: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  marginTop: 20,
}
const title: React.CSSProperties = { fontSize: 18, fontWeight: 700 }
const titleMobile: React.CSSProperties = { fontSize: 18, fontWeight: 700, marginTop: 20 }
const activeText: React.CSSProperties = { fontSize: 18, fontWeight: 700, color: '#15D4B1' }
const left1: React.CSSProperties = { display: 'flex', width: '50%', alignItems: 'center' }
const left1Mobile: React.CSSProperties = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
}
const right1: React.CSSProperties = {
  display: 'flex',
  width: '60%',
  justifyContent: 'space-between',
  alignItems: 'center',
}
const right1Mobile: React.CSSProperties = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: 30,
  marginBottom: 30,
}
const left2: React.CSSProperties = {
  display: 'flex',
  width: '40%',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRight: '1px solid #15D4B1',
  paddingRight: 15,
}
const left2Mobile: React.CSSProperties = {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexDirection: 'column',
}
const right2: React.CSSProperties = {
  width: '60%',
  display: 'flex',
  justifyContent: 'space-between',
  columnGap: 20,
  alignItems: 'center',
  paddingLeft: 4,
}
const right2Mobile: React.CSSProperties = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  rowGap: 8,
  alignItems: 'center',
  flexDirection: 'column',
}
const inputStyle: React.CSSProperties = {
  fontSize: 16,
  fontWeight: 400,
  color: '#8B8B8B',
  border: '1px solid #8B8B8B',
  padding: 15,
  borderRadius: 10,
  background: 'transparent',
  width: '60%',
  maxWidth: '180px',
  maxHeight: '55px',
}
const inputStyleMobile: React.CSSProperties = {
  fontSize: 16,
  fontWeight: 400,
  color: '#8B8B8B',
  border: '1px solid #8B8B8B',
  padding: 15,
  borderRadius: 10,
  background: 'transparent',
  marginBottom: 20,
  width: '100%',
}
const textStyle: React.CSSProperties = { fontSize: 16, fontWeight: 600 }
const flex: React.CSSProperties = { display: 'flex', justifyContent: 'space-between' }
const flexMobile: React.CSSProperties = { display: 'flex', flexDirection: 'column' }
