import useSWR from "swr";
import { REFERRAL_STAKING_API } from "../../../config/constants/endpoints";

const fetchWeeklyRank = async () => {
  const response = await fetch(`${REFERRAL_STAKING_API}/weekly-rank`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  const result = await response.json()
  return result
}

export const useWeeklyRank = () => {
  return useSWR(['weekly-rank'], () => {
    return fetchWeeklyRank()
  })
}
