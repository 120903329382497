import { ethers } from 'ethers'
import { BigNumber } from '@ethersproject/bignumber'
import { useState, useEffect, useCallback } from 'react'
import { getContract } from "utils/contractHelpers";
import { useAccount, useBlockNumber } from 'wagmi'
import Erc20 from 'config/abi/erc20.json';
import useNameServiceContract from "./useNameServiceContract";
import Pose from "../../../config/abi/poseInterface.json";

const usePoseBalance = () => {
  const { address: account } = useAccount()
  const { listSingleEntries } = useNameServiceContract()
  const [balance, setBalance] = useState<BigNumber>(ethers.constants.Zero);
  const [ngBalance, setNgBalance] = useState<BigNumber>(ethers.constants.Zero);
  const { data: blockNumber } = useBlockNumber({watch: true})

  const getPoseBalance = useCallback(async () => {
    if (account && listSingleEntries?.Pose) {
      const contract = getContract({ abi: Pose.abi, address: listSingleEntries.Pose.address });
      const rawBalance = await contract.balanceOf(account)
      setBalance(rawBalance)
      return
    }
    setBalance(ethers.constants.Zero)
  }, [account, listSingleEntries])

  const getPoseNgBalance = useCallback(async () => {
    if (account && listSingleEntries?.PoseNg) {
      const contract = getContract({ abi: Erc20, address: listSingleEntries.PoseNg.address });
      const rawBalance = await contract.balanceOf(account)
      setNgBalance(rawBalance)
      return
    }
    setNgBalance(ethers.constants.Zero)
  }, [account, listSingleEntries])

  useEffect(() => {
    getPoseBalance()
    getPoseNgBalance()
  }, [getPoseBalance, getPoseNgBalance, blockNumber])

  const getPoseAllowance = useCallback(
    async (contractAddress: string) => {
      if (listSingleEntries?.Pose && account && contractAddress) {
        const contract = getContract({ abi: Pose.abi, address: listSingleEntries.Pose.address });
        const allowance = await contract.allowance(account, contractAddress)
        return allowance as BigNumber
      }
      return ethers.constants.Zero
    },
    [account, listSingleEntries],
  )

  const getPoseNgAllowance = useCallback(
    async (contractAddress: string) => {
      if (listSingleEntries?.PoseNg && account && contractAddress) {
        const contract = getContract({ abi: Erc20, address: listSingleEntries.PoseNg.address });
        const allowance = await contract.allowance(account, contractAddress)
        return allowance as BigNumber
      }
      return ethers.constants.Zero
    },
    [account, listSingleEntries],
  )

  return { balance, getPoseAllowance, ngBalance, getPoseNgAllowance }
}

export default usePoseBalance
