import { createContext } from 'react'

export interface IListSingleEntries {
  AggregateCall: ISingleEntries
  GetRewardPoseDispatch: ISingleEntries
  InvitationCenter: ISingleEntries
  Pose: ISingleEntries
  PoseNg: ISingleEntries
  PosePool: ISingleEntries
  StakePoseMix: ISingleEntries
  Vesting: ISingleEntries
  ZepochNode: ISingleEntries
}
export interface ISingleEntries {
  name: string
  address: string
}

export interface IPerson {
  stakedSourceAmount: any
  accumulativeReward: any
  accumulativeBonus1: any
  accumulativeBonus2: any
  bonus1: any
  bonus2: any
  isAngel: boolean
  stakedPoseNgAmount: any
}

export interface IBill {
  id: any
  amount: any
  depositTimestamp: any
  lastGetRewardTimestamp: any
  rewardOriginal: any
  rewardPerTotalSourcePaid: any
  withdrawUnlockTimestamp: any
  withdrawn: any
  rewardTaken: any
  who: any
  mintedVoteAmount: any;
  billStatus: any;
  ngDepositTimestamp: any;
  ngPoseAmount: any;
  ngLinearRewardPoseAmount: any;
  ngLinearRewardLastUpdateTimestamp: any;
  ngLinearRewardClaimedPoseAmount: any;
  ngEndRewardPoseAmount: any;
  ngCapitalClaimMonth: any;
  ngWithdrawnAmount: any;
  ngUnlockFinished: any;
  ngWithdrawn: any;
}

export const MAX_BONUS = 30
export const MILLISECONDS_IN_DAY = 86400000

export const PoseStakingContext = createContext<{
  listSingleEntries: IListSingleEntries
  person: IPerson
  rootInviterCode?: string
  inviterCode?: string
  inviteeRecordsLength?: any
}>({
  listSingleEntries: null,
  person: null,
})

export const BILL_TYPE_OLD = 0;
export const BILL_TYPE_NEW = 1;
export const BILL_TYPE_TRANSFORMED = 2;
export const BILL_TYPE_WITHDRAWING_REWARD = 3;
