import { ethers } from 'ethers'
import { useTranslation } from '@pancakeswap/localization'
import { Button, useModal } from "@pancakeswap/uikit"
import Countdown from 'react-countdown'

import Image from 'next/image'
import { useState, useContext, useMemo, useEffect } from "react";
import useResponsive from 'hooks/useResponsive'
import {
  PoseStakingContext,
  MILLISECONDS_IN_DAY,
  IBill,
  BILL_TYPE_OLD,
} from "views/PoseStaking/context";
import { formatUnits } from '@ethersproject/units'
import usePosePoolContract from 'views/PoseStaking/hooks/usePosePoolContract'
import useNameServiceContract from 'views/PoseStaking/hooks/useNameServiceContract'
import { formatBigNumber } from '@pancakeswap/utils/formatBalance'
import { BigNumber } from "@ethersproject/bignumber";
import ArrowUp from '../../pngs/arrow_up.png'
import ArrowDown from '../../pngs/arrow_down.png'
import MigrateModal from "./MigrateModal";

const StakeHistory = () => {
  const { t } = useTranslation()
  const { isMobile } = useResponsive()
  const { person } = useContext(PoseStakingContext)
  const { listSingleEntries } = useNameServiceContract()
  const {
    apy,
    config,
    bills: billsData,
    ngBills: ngBillsData,
    linearUnlockNg,
    linearUnlockNgLoading,
    withdrawMonthlyNg,
    withdrawMonthlyNgLoading,
    staticLinearUnlockNg,
    staticWithdrawMonthlyNg,
    migrateBillLoading,
    withdrawCloseOld,
    withdrawCloseOldLoading,
  } = usePosePoolContract(listSingleEntries && listSingleEntries.PosePool.address)
  const [rowSelected, setRowSelected] = useState<string | undefined>()

  const totalPoseToMigrate = useMemo(() => {
    return billsData?.filter(b => BigNumber.from(BILL_TYPE_OLD)?.eq(b?.billStatus ?? 0) && !b?.withdrawn)
      ?.map(b => b.amount)
      ?.reduce((a, a0) => a.add(a0), ethers.constants.Zero)
  }, [billsData])

  const [onMigrateModal] = useModal(<MigrateModal poseAmount={totalPoseToMigrate} apy={apy} lockDays={BigNumber.from(360)} />)

  const onClickCollapse = (id: string) => {
    if (id === rowSelected) {
      setRowSelected(undefined)
      return
    }
    setRowSelected(id)
  }

  const calcClaimable = (b: IBill) => {
    const maxAmount = (b?.ngPoseAmount || ethers.constants.Zero).div(2).sub(b.ngLinearRewardClaimedPoseAmount);
    const reward = (b.ngLinearRewardPoseAmount || ethers.constants.Zero)
      .mul(BigNumber.from(nowDate.getTime()).div(1000).sub(b.ngLinearRewardLastUpdateTimestamp))
      .div(config?.oneDay ?? 60 * 60 * 24).div(360);
    return reward.gt(maxAmount) ? (maxAmount.gt(ethers.constants.Zero) ? maxAmount : ethers.constants.Zero) : reward;
  }

  const [totalClaimable, setTotalClaimable] = useState(ethers.constants.Zero)
  const [totalClaimableFromContract, setTotalClaimableFromContract] = useState(ethers.constants.Zero)
  const [totalWithdrawMonthlyNg, setTotalWithdrawMonthlyNg] = useState(ethers.constants.Zero)
  const [withdrawableIdMap, setWithdrawableIdMap] = useState({})

  const newBills = useMemo(() => ngBillsData?.filter(b => !BigNumber.from(BILL_TYPE_OLD)?.eq(b?.billStatus ?? 0)), [ngBillsData])

  useEffect(() => {
    if (newBills?.length) {
      staticLinearUnlockNg()
        .then(r => setTotalClaimableFromContract(r))
        .catch(e => console.log(`staticLinearUnlockNg error: `, e))
      const newUnwithdrawnBills = newBills?.filter(b => !b.ngWithdrawn)
      staticWithdrawMonthlyNg(newUnwithdrawnBills?.map(b => b.id))
        .then(r => setTotalWithdrawMonthlyNg(r))
        .catch(e => console.log(`staticWithdrawMonthlyNg total error: `, e))
      newUnwithdrawnBills.forEach(b => {
        staticWithdrawMonthlyNg([b.id])
          .then(r => {
            if (!ethers.constants.Zero.eq(r)) {
              setWithdrawableIdMap(m => ({...m, [b.id]: r}))
            }
          })
          .catch(e => console.log(`staticWithdrawMonthlyNg ${b.id} error: `, e))
      })
      const tc = newBills.map(b => calcClaimable(b))
        .reduce((a0, a1) => a0.add(a1));
      setTotalClaimable(tc);
    }
  }, [newBills, staticLinearUnlockNg, staticWithdrawMonthlyNg])

  const canAllWithdraw = useMemo(() => {
    return newBills?.find(b => b.ngLinearRewardLastUpdateTimestamp.mul(1000).add(15 * 24 * 60 * 60 * 1000).lt(Date.now()) && !b.ngWithdrawn)
  }, [newBills])

  const getHoldBonusPercent = (bill: IBill) => {
    const holdTime = Date.now() - bill.lastGetRewardTimestamp.toNumber() * 1000
    const holdDays = Math.floor(holdTime / MILLISECONDS_IN_DAY)
    const percent = Math.min(holdDays, 30)
    return percent
  }

  const nowDate = new Date()

  const getButton = (item: IBill) => {
    if (BigNumber.from(BILL_TYPE_OLD)?.eq(item?.billStatus ?? 0)) {
      return <></>
    }
    const nextClaimTimestamp = item.ngLinearRewardLastUpdateTimestamp.mul(1000).add(15 * 24 * 60 * 60 * 1000);
    if (nextClaimTimestamp.lt(Date.now()) && !item.ngWithdrawn) {
      return (
        <Button
          onClick={() => withdrawMonthlyNg([item.id])}
          variant="secondary"
          height="45px"
          width="130px"
          style={buttonWithdraw}
          isLoading={withdrawMonthlyNgLoading}
        >
          <p style={textButton}>{t('Withdraw')}</p>
        </Button>
      )
    }
    if (
      item.ngLinearRewardLastUpdateTimestamp.gt(0) &&
      nextClaimTimestamp.gt(Date.now()) &&
      !item.ngWithdrawn
    ) {
      return (
        <Button variant="secondary" height="45px" width="130px" style={buttonWithdraw} disabled>
          <p style={textButton}>{t('Pending')}</p>
          <Countdown date={nextClaimTimestamp.toNumber()} daysInHours />
        </Button>
      )
    }
    return (
      <Button variant="primary" height="45px" width="130px" style={buttonWithdraw} disabled>
        <p style={textButton}>{t('Withdrawn')}</p>
      </Button>
    )
  }

  return (
    <div style={layout}>
      <p style={title}>{t('Your Stake History')}</p>
      <div style={table}>
        {isMobile ? (
          <div style={rowHeaderStyleMobile}>
            <div style={rowMobileStyle}>
              <div style={{ ...cellMobile, width: '100%', textAlign: 'center' }}>
                <p style={rowHeaderLabel}>{t('Total Referral Earned')}</p>
                <p style={rowHeaderContent}>
                  {formatBigNumber(person.accumulativeBonus1.add(person.accumulativeBonus2), 2)} POSE
                </p>
              </div>
            </div>
            <div style={rowMobileStyle}>
              <div style={{ ...cellMobile, width: '100%', textAlign: 'center' }}>
                <p style={rowHeaderLabel}>{t('Claimable')}</p>
                <p style={rowHeaderContent}>{formatBigNumber(totalClaimable || ethers.constants.Zero, 2)} POSE</p>
              </div>
            </div>
            <div style={cellActionMobileHeader}>
              <Button
                onClick={() => linearUnlockNg()}
                disabled={totalClaimableFromContract.lte(0) || linearUnlockNgLoading}
                width="120px"
                variant="secondary"
                height="41px"
                isLoading={linearUnlockNgLoading}
              >
                {t('Claim All')}
              </Button>
              <Button
                onClick={() => withdrawMonthlyNg(newBills?.filter((b) => !b.ngWithdrawn)?.map((b) => b.id))}
                disabled={!canAllWithdraw}
                width="120px"
                variant="secondary"
                height="41px"
                isLoading={withdrawMonthlyNgLoading}
              >
                {t('Withdraw All')}
              </Button>
            </div>
            {billsData?.find((b) => BigNumber.from(BILL_TYPE_OLD)?.eq(b?.billStatus ?? 0) && !b?.withdrawn) ? (
              <div style={cellActionMobileHeader}>
                <Button
                  onClick={onMigrateModal}
                  width="120px"
                  variant="secondary"
                  height="41px"
                  isLoading={migrateBillLoading}
                >
                  {t('Migrate')}
                </Button>
                <Button
                  onClick={() => withdrawCloseOld()}
                  width="120px"
                  variant="secondary"
                  height="41px"
                  isLoading={withdrawCloseOldLoading}
                >
                  {t('Redeem')}
                </Button>
              </div>
            ) : null}
          </div>
        ) : (
          <div style={rowHeaderStyle}>
            <div style={cellHeader}>
              <p style={rowHeaderLabel}>{t('Total Referral Earned')}</p>
              <p style={rowHeaderContent}>
                {formatBigNumber(
                  person ? person.accumulativeBonus1.add(person.accumulativeBonus2) : ethers.constants.Zero,
                  2,
                )}{' '}
                POSE
              </p>
            </div>
            <div style={cellHeader}>
              <p style={rowHeaderLabel}>{t('Claimable')}</p>
              <p style={rowHeaderContent}>{formatBigNumber(totalClaimable || ethers.constants.Zero, 2)} POSE</p>
            </div>
            <div style={{ ...cellAction, width: '33%' }}>
              <div style={actionCellView}>
                <Button
                  onClick={() => linearUnlockNg()}
                  disabled={totalClaimableFromContract.lte(0) || linearUnlockNgLoading}
                  width="130px"
                  variant="secondary"
                  height="35px"
                  minHeight={35}
                  isLoading={linearUnlockNgLoading}
                >
                  {t('Claim All')}
                </Button>
                <Button
                  onClick={() => withdrawMonthlyNg(newBills?.map((b) => b.id))}
                  disabled={!canAllWithdraw}
                  width="130px"
                  variant="secondary"
                  height="35px"
                  minHeight={35}
                  padding={0}
                  isLoading={withdrawMonthlyNgLoading}
                >
                  {t('Withdraw All')}
                </Button>
              </div>
              {billsData?.find((b) => BigNumber.from(BILL_TYPE_OLD)?.eq(b?.billStatus ?? 0) && !b?.withdrawn) ? (
                <div style={actionCellView}>
                  <Button
                    onClick={onMigrateModal}
                    width="150px"
                    variant="secondary"
                    height="35px"
                    minHeight={35}
                    isLoading={migrateBillLoading}
                  >
                    {t('Migrate')}
                  </Button>
                  <Button
                    onClick={() => withdrawCloseOld()}
                    width="150px"
                    variant="secondary"
                    height="35px"
                    minHeight={35}
                    isLoading={withdrawCloseOldLoading}
                  >
                    {t('Redeem')}
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        )}
        {newBills &&
          newBills?.map((item, index) => (
            <div key={formatUnits(item.id, 0).toString()}>
              <div style={index === 0 ? firstRowStyle : index === newBills.length - 1 ? lastRowStyle : rowStyle}>
                <div style={isMobile ? cellMobile : cell}>
                  <p style={label}>{t('Stake Date')}</p>
                  <p>{new Date(Number(`${formatUnits(item?.ngDepositTimestamp || 0, 0)}000`)).toLocaleDateString()}</p>
                </div>
                <div style={isMobile ? cellMobile : cell}>
                  <p style={label}>{t('Lock Time')}</p>
                  <p>
                    {360 -
                      Math.floor(
                        (nowDate.getTime() - Number(`${formatUnits(item?.ngDepositTimestamp || 0, 0)}000`)) /
                          1000 /
                          60 /
                          60 /
                          24,
                      )}{' '}
                    {t('Days')}
                  </p>
                </div>
                {!isMobile && (
                  <>
                    <div style={isMobile ? cellMobile : cell}>
                      <p style={label}>{t('Staked Amount')}</p>
                      <p style={centerCell}>{formatBigNumber(item.ngPoseAmount || ethers.constants.Zero, 2)}</p>
                    </div>
                    <div style={isMobile ? cellMobile : cell}>
                      <p style={label}>{t('Principal Unlocked')}</p>
                      <p style={centerCell}>
                        {formatBigNumber(withdrawableIdMap[item.id] || ethers.constants.Zero, 2)}
                      </p>
                    </div>
                    <div style={isMobile ? cellMobile : cell}>
                      <p style={label}>{t('Claimable Rewards')}</p>
                      <p style={centerCell}>
                        {/* {item.ngLinearRewardLastUpdateTimestamp.add(config?.oneDay.mul(15)).mul(1000).gt(nowDate.getTime()) ? 0 : */}
                        {/*   formatBigNumber((item.ngLinearRewardPoseAmount || ethers.constants.Zero).mul(BigNumber.from(nowDate.getTime()).div(1000).sub(item.ngLinearRewardLastUpdateTimestamp)).div(config?.oneDay ?? 1).div(360), 4)} */}
                        {formatBigNumber(calcClaimable(item), 2)}
                      </p>
                    </div>
                    <div style={isMobile ? cellMobile : cell}>
                      <p style={label}>{t('Principal Withdrawn')}</p>
                      <p style={centerCell}>{formatBigNumber(item?.ngWithdrawnAmount || ethers.constants.Zero, 2)}</p>
                    </div>
                  </>
                )}
                {!isMobile ? (
                  <div style={isMobile ? arrowMobile : cellAction}>{getButton(item)}</div>
                ) : (
                  <div style={isMobile ? arrowMobile : cellAction}>
                    {rowSelected === formatUnits(item.id, 0).toString() ? (
                      <div style={arrow}>
                        <Image
                          onClick={() => onClickCollapse(formatUnits(item.id, 0).toString())}
                          src={ArrowUp}
                          alt="arrow-up"
                        />
                      </div>
                    ) : (
                      <div style={arrow}>
                        <Image
                          onClick={() => onClickCollapse(formatUnits(item.id, 0).toString())}
                          src={ArrowDown}
                          alt="arrow-down"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              {rowSelected === formatUnits(item.id, 0).toString() && (
                <div>
                  {isMobile && (
                    <div style={expandedRowMobile}>
                      <div style={rowMobileStyle}>
                        <div style={isMobile ? cellMobile : cell}>
                          <p style={label}>{t('Staked Amount')}</p>
                          <p style={centerCell}>{formatBigNumber(item.ngPoseAmount || ethers.constants.Zero, 2)}</p>
                        </div>
                        <div style={isMobile ? cellMobile : cell}>
                          <p style={label}>{t('Principal Unlocked')}</p>
                          <p style={centerCell}>
                            {formatBigNumber(withdrawableIdMap[item.id] || ethers.constants.Zero, 2)}
                          </p>
                        </div>
                      </div>
                      <div style={rowMobileStyle}>
                        <div style={isMobile ? cellMobile : cell}>
                          <p style={label}>{t('Claimable Rewards')}</p>
                          <p style={centerCell}>
                            {/* {item.ngLinearRewardLastUpdateTimestamp.add(config?.oneDay.mul(15)).mul(1000).gt(nowDate.getTime()) ? 0 : */}
                            {/*   formatBigNumber((item.ngLinearRewardPoseAmount || ethers.constants.Zero).mul(BigNumber.from(nowDate.getTime()).div(1000).sub(item.ngLinearRewardLastUpdateTimestamp)).div(config?.oneDay ?? 1).div(360), 4)} */}
                            {formatBigNumber(calcClaimable(item), 2)}
                          </p>
                        </div>
                        <div style={isMobile ? cellMobile : cell}>
                          <p style={label}>{t('Principal Withdrawn')}</p>
                          <p style={centerCell}>
                            {formatBigNumber(item?.ngWithdrawnAmount || ethers.constants.Zero, 2)}
                          </p>
                        </div>
                      </div>
                      <div style={rowMobileStyleCenter}>
                        <div style={cellMobileCenter}>{getButton(item)}</div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        {billsData &&
          billsData?.map((item, index) => (
            <div key={formatUnits(item.id, 0).toString()}>
              <div style={index === 0 ? firstRowStyle : index === billsData.length - 1 ? lastRowStyle : rowStyle}>
                <div style={isMobile ? cellMobile : cell}>
                  <p style={label}>{t('Stake Date')}</p>
                  <p>{new Date(Number(`${formatUnits(item.depositTimestamp, 0)}000`)).toLocaleDateString()}</p>
                </div>
                <div style={isMobile ? cellMobile : cell}>
                  <p style={label}>{t('Amount')}</p>
                  <p>{formatBigNumber(item.amount || ethers.constants.Zero, 2)} POSE</p>
                </div>
                {!isMobile && (
                  <div style={cell}>
                    <p style={label}>{t('Total Earned')}</p>
                    <p>{formatBigNumber(item.rewardTaken || ethers.constants.Zero, 2)} POSE</p>
                  </div>
                )}
                {!isMobile && !newBills?.length && (
                  <div style={cell}>
                    <p style={label}>{t('Hold Bonus')}</p>
                    <p>{`+${getHoldBonusPercent(item)}%`}</p>
                  </div>
                )}
                {/* {!isMobile && (
                  <div style={cell}>
                    <p style={label}>{t('Total Available')}</p>
                    <p>
                      {formatBigNumber(
                        item.amount
                          .add(item.rewardOriginal)
                          .add(item.rewardOriginal.mul(getHoldBonusPercent(item)).div(100)),
                        4,
                      )}
                      POSE
                    </p>
                  </div>
                )} */}
                {!isMobile ? (
                  <div style={isMobile ? arrowMobile : { ...cellAction, width: 0 }}>{getButton(item)}</div>
                ) : (
                  <div style={isMobile ? arrowMobile : cellAction}>
                    {rowSelected === formatUnits(item.id, 0).toString() ? (
                      <div style={arrow}>
                        <Image
                          onClick={() => onClickCollapse(formatUnits(item.id, 0).toString())}
                          src={ArrowUp}
                          alt="arrow-up"
                        />
                      </div>
                    ) : (
                      <div style={arrow}>
                        <Image
                          onClick={() => onClickCollapse(formatUnits(item.id, 0).toString())}
                          src={ArrowDown}
                          alt="arrow-down"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              {rowSelected === formatUnits(item.id, 0).toString() && (
                <div>
                  {isMobile && (
                    <div style={expandedRowMobile}>
                      <div style={rowMobileStyle}>
                        <div style={cellMobile}>
                          <p style={label}>{t('Total Earned')}</p>
                          <p>{formatBigNumber(item.rewardTaken || ethers.constants.Zero, 2)} POSE</p>
                        </div>
                        {!newBills?.length && (
                          <div style={cellMobile}>
                            <p style={label}>{t('Hold Bonus')}</p>
                            <p>{`+${getHoldBonusPercent(item)}%`}</p>
                          </div>
                        )}
                        {/* <div style={cellMobile}>
                          <p style={label}>{t('Total Available')}</p>
                          <p>
                            {formatBigNumber(
                              item.amount
                                .add(item.rewardOriginal)
                                .add(item.rewardOriginal.mul(getHoldBonusPercent(item)).div(100)),
                              4,
                            )}
                            POSE
                          </p>
                        </div> */}
                      </div>
                      <div style={rowMobileStyleCenter}>
                        <div style={cellMobileCenter}>{getButton(item)}</div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}

export default StakeHistory

const layout: React.CSSProperties = {
  marginTop: 70,
  display: 'flex',
  flexDirection: 'column',
}
const title: React.CSSProperties = { fontSize: 20, fontWeight: 700, textAlign: 'center' }
const table: React.CSSProperties = { marginTop: 0 }
const rowStyle: React.CSSProperties = {
  position: 'relative',
  display: 'flex',
  background: 'linear-gradient(267.79deg, rgba(62, 78, 225, 0.3) 0%, rgba(1, 163, 143, 0.3) 100%)',
  width: '100%',
  height: 67,
  padding: 15,
  borderTop: '1px solid #303C3E',
  justifyContent: 'space-between',
}
const firstRowStyle: React.CSSProperties = {
  display: 'flex',
  background: 'linear-gradient(267.79deg, rgba(62, 78, 225, 0.3) 0%, rgba(1, 163, 143, 0.3) 100%)',
  width: '100%',
  height: 67,
  borderRadius: '10px 10px 0px 0px',
  padding: 15,
  position: 'relative',
  justifyContent: 'space-between',
  marginTop: 6,
}
const lastRowStyle: React.CSSProperties = {
  display: 'flex',
  background: 'linear-gradient(267.79deg, rgba(62, 78, 225, 0.3) 0%, rgba(1, 163, 143, 0.3) 100%)',
  width: '100%',
  height: 67,
  borderRadius: ' 0px 0px 10px 10px',
  padding: 15,
  borderTop: '1px solid #303C3E',
  position: 'relative',
  justifyContent: 'space-between',
}
const expandedRow: React.CSSProperties = {
  display: 'flex',
  background: '#030B24',
  height: 71,
  padding: 15,
}
const expandedRowMobile: React.CSSProperties = {
  display: 'flex',
  background: '#030B24',
  padding: 15,
  flexDirection: 'column',
  gap: 30,
}
const rowHeaderStyle: React.CSSProperties = {
  display: 'flex',
  width: '100%',
  height: 127,
  padding: 15,
}
const rowHeaderStyleMobile: React.CSSProperties = {
  display: 'flex',
  width: '100%',
  marginTop: 30,
  marginBottom: 30,
  flexDirection: 'column',
  gap: 30,
}
const cell: React.CSSProperties = {
  width: '30%',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  display: 'flex',
}
const cellHeader: React.CSSProperties = {
  width: '33%',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  display: 'flex',
}
const cellMobile: React.CSSProperties = {
  width: '50%',
  justifyContent: 'center',
  flexDirection: 'column',
  display: 'flex',
}
const cellMobileCenter: React.CSSProperties = {
  width: '50%',
  justifyContent: 'center',
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
}
const cellAction: React.CSSProperties = {
  width: '18%',
  alignItems: 'center',
  justifyContent: 'end',
  flexDirection: 'row',
  display: 'flex',
}
const cellActionMobileHeader: React.CSSProperties = {
  width: '100%',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  display: 'flex',
  gap: 10,
}
const label: React.CSSProperties = {
  fontSize: 12,
  color: '#C5C5C5',
  fontWeight: 400,
  marginBottom: 15,
}
const centerCell: React.CSSProperties = {
  textAlign: 'left',
}
const rowHeaderLabel: React.CSSProperties = {
  fontSize: 18,
  fontWeight: 400,
  marginBottom: 15,
}
const rowHeaderContent: React.CSSProperties = {
  fontSize: 18,
  fontWeight: 700,
  color: '#15D4B1',
}
const arrow: React.CSSProperties = {
  cursor: 'pointer',
  padding: 15,
}
const arrowMobile: React.CSSProperties = {
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  display: 'flex',
  position: 'absolute',
  top: 12,
  right: 12,
}
const rowMobileStyle: React.CSSProperties = { display: 'flex' }
const rowMobileStyleCenter: React.CSSProperties = { display: 'flex', justifyContent: 'center' }

const actionCellView: React.CSSProperties = {
  gap: 4,
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  alignItems: 'center',
}
const buttonWithdraw: React.CSSProperties = { flexDirection: 'column', gap: 4, fontSize: 14, fontWeight: 400 }
const textButton: React.CSSProperties = { fontSize: 14, fontWeight: 600 }
const textTimeButton: React.CSSProperties = { fontSize: 14, fontWeight: 600, color: '#15D4B1' }
