import { useMediaQuery } from 'react-responsive'

export interface IResponsive {
  isDesktopOrLaptop: boolean
  isMobile: boolean
  isTablet: boolean
  isTabletOrMobile: boolean
}

export const screen = {
  width: {
    desktop: 1340,
    tablet: 768,
    mobile: 480,
  },
}

const useResponsive = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${screen.width.desktop}px)`,
  })
  const isTablet = useMediaQuery({
    query: `(max-width: ${screen.width.desktop}px) and (min-width: ${screen.width.tablet}px)`,
  })
  const isMobile = useMediaQuery({
    query: `(max-width: ${screen.width.tablet}px) and (min-width: 0px)`,
  })
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${screen.width.desktop}px)`,
  })

  return {
    isDesktopOrLaptop,
    isMobile,
    isTablet,
    isTabletOrMobile,
  }
}

export default useResponsive
