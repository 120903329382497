import { ethers } from 'ethers'
import { formatBytes32String } from '@ethersproject/strings'
import { useTranslation } from '@pancakeswap/localization'
import { Button, Flex, InjectedModalProps, Modal, useToast } from '@pancakeswap/uikit'
import useResponsive from 'hooks/useResponsive'
import usePoseBalance from 'views/PoseStaking/hooks/usePoseBalance'
import usePosePoolContract from 'views/PoseStaking/hooks/usePosePoolContract'
import { formatBigNumber } from '@pancakeswap/utils/formatBalance'
import styled from 'styled-components'
import { BigNumber } from "@ethersproject/bignumber";
import useInvitationCenterContract from '../../hooks/useInvitationCenterContract'
import useNameServiceContract from '../../hooks/useNameServiceContract'

const CustomModal = styled(Modal)`
  bottom: auto !important;
`

const MigrateModal: React.FC<React.PropsWithChildren<InjectedModalProps> & { poseAmount: BigNumber, apy: BigNumber, lockDays: BigNumber, referralCode?: string }> = ({
  onDismiss,
  poseAmount,
  apy,
  lockDays,
  referralCode,
}) => {
  const { t } = useTranslation()
  const { isMobile } = useResponsive()
  const { listSingleEntries } = useNameServiceContract()
  const { balance, getPoseAllowance } = usePoseBalance()
  const {
    stake, approve, config, migrateBill,
    migrateBillLoading
  } = usePosePoolContract(
    listSingleEntries && listSingleEntries.PosePool.address
  );
  const defaultReferralCode = "2LSZHUV6"

  const { isRegistered } = useInvitationCenterContract(listSingleEntries && listSingleEntries.InvitationCenter.address)
  const { toastError } = useToast()

  const onClickMigrate = async () => {
    if (!(listSingleEntries)) {
      return
    }
    if (ethers.constants.Zero.gte(poseAmount ?? ethers.constants.Zero)) {
      toastError(t('Have no POSE to migrate'))
      return
    }
    await migrateBill(ethers.constants.HashZero); // User who can migrate is already registered, contract need hashZero here
    onDismiss()
  }

  return (
    <CustomModal title={null} onDismiss={onDismiss}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
        <Flex flexDirection="row" justifyContent="start" width="100%" style={{marginBottom: 15}}>
          <div style={{ fontSize: 24, fontStyle: 'normal',fontWeight: 700, lineHeight: 'normal' }}>
            {t('Migrate')}
          </div>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="460px"
          padding="6px"
          style={isMobile ? mobileModal : modal}
        >
          <Flex flexDirection="row" justifyContent="space-between" width="100%" style={{marginBottom: 20}}>
            <div style={title}>{t('$POSE to be migrated')}</div>
            <div style={activeText}>{formatBigNumber(poseAmount, 4)} POSE</div>
          </Flex>
          <Flex flexDirection="row" justifyContent="space-between" width="100%" style={{marginBottom: 20}}>
            <div style={title}>{t('APY')}</div>
            <div style={activeText}>{formatBigNumber(apy, 4)}%</div>
          </Flex>
          <Flex flexDirection="row" justifyContent="space-between" width="100%" style={{marginBottom: 28.5}}>
            <div style={title}>{t('Lock Time')}</div>
            <div style={activeText}>{lockDays.toNumber()} Days</div>
          </Flex>
          <div style={{ flexShrink: 0, color: 'rgba(200, 200, 200, 0.50)', fontSize: 14, fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal' }}>
            <p style={{ color: '#C8C8C8', fontSize: 18, marginBottom: 28.5 }}>
              Unlock Rule：
            </p>
            <p style={{ marginBottom: 20 }}>
              Tract 1: 50% of the APY will be streamed on a per-second basis to the token holder who will be able to make withdrawals of those rewards once every 15 days.
            </p>
            <p style={{ marginBottom: 20 }}>
              Tract 2: The other 50% of the rewards can be withdrawn at the end of the 1-year automatic staking period.
            </p>
            <p style={{ marginBottom: 40 }}>
              3% of the principal can be withdrawn each month. However, doing so will reduce the reward in tract 2 by 3%.
            </p>
          </div>

          <Button
            isLoading={migrateBillLoading}
            disabled={migrateBillLoading}
            onClick={onClickMigrate}
            width="100%"
            variant="success"
            height="48px"
          >
            Confirm
          </Button>
          <Button
            onClick={onDismiss}
            width="100%"
            variant="text"
            height="48px"
          >
            Cancel
          </Button>
        </Flex>
      </div>
    </CustomModal>
  )
}

export default MigrateModal

const modal: React.CSSProperties = { width: '460px', padding: '6px' }
const mobileModal: React.CSSProperties = { width: '320px', padding: '6px' }
const title: React.CSSProperties = { fontSize: 18, fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal' }
const titleMobile: React.CSSProperties = { fontSize: 18, fontWeight: 700, marginTop: 20 }
const activeText: React.CSSProperties = { color: '#15D4B1', textAlign: 'right', fontSize: 18, fontStyle: 'normal', fontWeight: 700, lineHeight: 'normal' }
const inputStyle: React.CSSProperties = {
  fontSize: 16,
  fontWeight: 400,
  color: '#8B8B8B',
  border: '1px solid #8B8B8B',
  padding: '20px 30px',
  borderRadius: 10,
  background: 'transparent',
  width: '100%',
}
const inputStyleMobile: React.CSSProperties = {
  fontSize: 16,
  fontWeight: 400,
  color: '#8B8B8B',
  border: '1px solid #8B8B8B',
  padding: 15,
  borderRadius: 10,
  background: 'transparent',
  marginBottom: 20,
  width: '100%',
}
