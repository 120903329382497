import useNameServiceContract from "./useNameServiceContract";
import usePosePoolContract from "./usePosePoolContract";

export const useWithdrawPose = () => {
  const { listSingleEntries } = useNameServiceContract()
  const {
    withdrawPose,
    withdrawPoseLoading
  } = usePosePoolContract(listSingleEntries && listSingleEntries.PosePool.address);

  return {
    withdrawPose,
    withdrawPoseLoading
  }
}
