import { Contract } from '@ethersproject/contracts'
import { useState, useEffect } from 'react'
import { getInvitationCenterContract } from 'utils/contractHelpers'
import { useAccount } from 'wagmi'
import useSWR from 'swr'

const REFRESH_INTERVAL = 5000

const useInvitationCenterContract = (contractAddress: string) => {
  const { address: account } = useAccount()
  const [invitationCenterContract, setInvitationCenterContract] = useState<Contract>()

  useEffect(() => {
    if (contractAddress) {
      const fetchPoseAmount = async () => {
        const contract = getInvitationCenterContract(contractAddress)
        setInvitationCenterContract(contract)
      }
      fetchPoseAmount()
    }
  }, [contractAddress])

  const { data: isRegistered } = useSWR(
    ['isRegistered', invitationCenterContract, account],
    async () => {
      const res = await invitationCenterContract.isRegistered(account)
      return res
    },
    { refreshInterval: REFRESH_INTERVAL },
  )
  const { data: rootInviter } = useSWR(
    ['getRootInviter', invitationCenterContract],
    async () => {
      const res = await invitationCenterContract.getRootInviter()
      return res
    },
    { refreshInterval: REFRESH_INTERVAL },
  )
  const getRootInviter = () => rootInviter?.invitationCode

  const { data: invInviterRecords } = useSWR(
    ['getInviterRecords', invitationCenterContract, account],
    async () => {
      const res = await invitationCenterContract.inviterRecords(account)
      return res
    },
    { refreshInterval: REFRESH_INTERVAL },
  )
  const getInviterRecords = () => invInviterRecords?.invitationCode

  const { data: inviteeRecordsLength } = useSWR(
    ['inviteeRecordsLength', invitationCenterContract && account],
    async () => {
      const res = await invitationCenterContract.inviteeRecordsLength(account)
      return res
    },
    { refreshInterval: REFRESH_INTERVAL },
  )
  const getInviteeRecordsLength = () => inviteeRecordsLength

  return { isRegistered, invitationCenterContract, getRootInviter, getInviterRecords, getInviteeRecordsLength }
}

export default useInvitationCenterContract
