import { useTranslation } from '@pancakeswap/localization'
import { BigNumberish, ethers } from 'ethers'
import { BigNumber } from '@ethersproject/bignumber'
import { useAccount } from 'wagmi'
import useSWR from 'swr'
import Image from 'next/image'
import styled from 'styled-components'
import { Button } from '@pancakeswap/uikit'
import useResponsive from 'hooks/useResponsive'
import { formatEther, parseEther } from '@ethersproject/units'
import { PoseStakingContext } from 'views/PoseStaking/context'
import { useContext, useState, useMemo, Fragment } from 'react'
import { formatBigNumber } from '@pancakeswap/utils/formatBalance'
import { parseBytes32String } from 'ethers/lib/utils'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import WeeklyRankIcon from '../../pngs/weekly_rank.png'
import ReferralIcon from '../../pngs/referral.png'
import RewardIcon from '../../pngs/reward.png'
import GoalMedalIcon from '../../pngs/gold_medal.png'
import SilverMedalIcon from '../../pngs/silver_medal.png'
import BronzeMedalIcon from '../../pngs/bronze_medal.png'
import Divide from '../../pngs/divide.png'
import Angel from '../../pngs/angel.png'
import Trident from '../../pngs/trident.png'

import { useWeeklyRank } from '../../hooks/useWeeklyRank'
import { REFERRAL_STAKING_API } from '../../../../config/constants/endpoints'
import { useWithdrawTickets } from '../../hooks/useWithdrawTickets'
import { useAnimationFrame } from '../../../../hooks/useAnimationFrame'
import { useWithdrawPose } from '../../hooks/useWithdrawPose'
import { WithdrawPoseParamStruct } from '../../../../config/abi/types/PosePoolInterface'

const TOTAL_REWARD = parseEther('9300')

const WeeklyRank = () => {
  const { t } = useTranslation()
  const { isMobile } = useResponsive()
  const { person, inviterCode, inviteeRecordsLength } = useContext(PoseStakingContext)
  const { address: account } = useAccount()

  const referralLink = inviterCode
    ? `https://poseiswap.xyz/posestaking?referralCode=${parseBytes32String(inviterCode)}`
    : ''

  const { data: weeklyRank, isLoading } = useWeeklyRank()
  const { data: referralStakingAmount } = useSWR(['referralStakingAmount', account], async () => {
    const response = await fetch(`${REFERRAL_STAKING_API}/stake-1-2?address=${account}`)
    return response.text()
  })
  const { data: referralStakingAmountIncludeSelf } = useSWR(['referralStakingAmountIncludeSelf', account], async () => {
    const response = await fetch(`${REFERRAL_STAKING_API}/stake-0-1-2?address=${account}`)
    return response.text()
  })

  const isTrident = useMemo(() => {
    return (
      BigNumber.from(referralStakingAmountIncludeSelf || ethers.constants.Zero).gte(ethers.utils.parseEther('50000')) &&
      person?.stakedSourceAmount?.gte(ethers.utils.parseEther('5000'))
    )
  }, [person?.stakedSourceAmount, referralStakingAmountIncludeSelf])

  const PAGE_SIZE = 10

  const totalPage = useMemo(() => {
    if (!weeklyRank) return 0
    return Math.ceil(weeklyRank.length / PAGE_SIZE)
  }, [weeklyRank])

  const [currentPage, setCurrentPage] = useState(0)

  const currentRanks = useMemo(() => {
    if (!weeklyRank) return []
    return weeklyRank.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE)
  }, [weeklyRank, currentPage])

  const goPage = (i: number) => {
    if (i < 0 || i >= totalPage) return
    setCurrentPage(i)
  }

  const { data: withdrawTickets } = useWithdrawTickets(account)

  const weekIndex = useMemo(() => {
    const firstWeekIndex = 19574
    return Math.floor((Math.floor(Date.now() / 1000 / 60 / 60 / 24) - firstWeekIndex) / 7) + 1
  }, [])

  const yourWeeklyRank = useMemo(() => {
    return weeklyRank?.find((item) => item.address === account)
  }, [weeklyRank, account])

  const yourWeeklyRankIndex = useMemo(() => {
    return weeklyRank?.findIndex((item) => item.address === account)
  }, [weeklyRank, account])

  const showRewardBottom = useMemo(() => {
    return withdrawTickets?.claimed || withdrawTickets?.tickets?.length > 0
  }, [withdrawTickets])

  const updateTime = useMemo(() => {
    const date = new Date((yourWeeklyRank?.date ?? weeklyRank?.[0]?.date) * 24 * 60 * 60 * 1000)
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
  }, [yourWeeklyRank, weeklyRank])

  const [nowTimeSecond, setNowTimeSecond] = useState<number>(0)

  useAnimationFrame(() => {
    const seconds = Math.floor(new Date().getTime() / 1000)
    if (seconds !== nowTimeSecond) setNowTimeSecond(seconds)
  })

  const releaseTime = useMemo(() => {
    let wDay = new Date(nowTimeSecond * 1000).getDay()

    if (wDay > 5) {
      wDay -= 7
    }

    const releaseSecond = Math.floor(nowTimeSecond / 60 / 60 / 24 + 5 - wDay) * 24 * 60 * 60 // Peking Time 8:00AM
    const CSTEndTimeOffsetInHour = -8
    const delayMinutes = 1 // delay 1 minute
    const seconds = releaseSecond - nowTimeSecond + delayMinutes * 60 + CSTEndTimeOffsetInHour * 60 * 60

    if (seconds < 0) return '00h: 00m: 00s'

    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const h = hours.toFixed().padStart(2, '0')
    const m = Math.floor(minutes % 60)
      .toFixed()
      .padStart(2, '0')
    const s = Math.floor(seconds % 60)
      .toFixed()
      .padStart(2, '0')

    return `${h}h: ${m}m: ${s}s`
  }, [nowTimeSecond])

  const allStakes = useMemo((): BigNumber[] => {
    if (!weeklyRank || !yourWeeklyRank) return []
    return weeklyRank.map(
      (item): BigNumber =>
        BigNumber.from(item.stake ?? 0)
          .add(BigNumber.from(item.stake1 ?? 0))
          .add(BigNumber.from(item.stake2 ?? 0)),
    )
  }, [weeklyRank, yourWeeklyRank])

  const estimateRewards = useMemo(() => {
    if (yourWeeklyRankIndex < 0 || !yourWeeklyRank?.rank) return ethers.constants.Zero
    const yourStakes = allStakes[yourWeeklyRankIndex ?? 0] ?? ethers.constants.Zero
    const partStakes =
      yourWeeklyRank?.rank <= 3
        ? allStakes.slice(0, 3).reduce((a, b) => a.add(b), ethers.constants.Zero)
        : allStakes.slice(3).reduce((a, b) => a.add(b), ethers.constants.Zero)
    return TOTAL_REWARD.mul(yourStakes).div(partStakes).div(2)
  }, [allStakes, yourWeeklyRank, yourWeeklyRankIndex])

  const unclaimReward = useMemo(() => {
    if (!withdrawTickets?.tickets) return ethers.constants.Zero
    return withdrawTickets?.tickets.reduce((a, b) => a.add(BigNumber.from(b.poseAmount)), ethers.constants.Zero)
  }, [withdrawTickets])

  const totallyClaimed = useMemo(() => BigNumber.from(withdrawTickets?.claimed ?? 0), [withdrawTickets])

  const { withdrawPose, withdrawPoseLoading } = useWithdrawPose()

  const handleClaimPose = async () => {
    if (withdrawTickets?.tickets?.length > 0) {
      const ticket = withdrawTickets?.tickets?.[0]
      const param: WithdrawPoseParamStruct = {
        who: ticket.who,
        poseAmount: ticket.poseAmount,
        ticketNumber: ticket.ticketNumber,
      }
      const signature = ethers.utils.splitSignature(ticket.signature)

      await withdrawPose(param, signature)
      // window.location.reload();
    }
  }

  if (ethers.constants.Zero.eq(unclaimReward)) {
    return (
      <div />
    )
  }

  return (
    <div style={isMobile ? layoutMobile : layout}>
      <div style={leftStyle(isMobile)}>
        <div style={isMobile ? headerTitleMobile : headerTitle}>
          <Image src={WeeklyRankIcon} alt="weekly-rank" />
          <span style={title(isMobile)}>{t('Weekly Rank')}</span>
        </div>
        <p style={WeeklyRankDesc}>{t('The ranking is updated every 24 hours')}</p>
        {!isLoading && weeklyRank && (
          <div style={table}>
            {currentRanks.map((item) => (
              <div key={item.address} style={rowStyle(isMobile)}>
                <div style={stt}>
                  {item.rank === 1 ? (
                    <Image src={GoalMedalIcon} alt="goal-medal" />
                  ) : item.rank === 2 ? (
                    <Image src={SilverMedalIcon} alt="silver-medal" />
                  ) : item.rank === 3 ? (
                    <Image src={BronzeMedalIcon} alt="bronze-medal" />
                  ) : (
                    <span style={textBold}>
                      {item.rank < 10 ? '0' : ''}
                      {item.rank}
                    </span>
                  )}
                </div>
                <div style={cell}>
                  <p style={label}>{t('Address')}</p>
                  <p style={text}>
                    {item.address.substring(0, 6)}...{item.address.substring(38, 42)}
                  </p>
                </div>
                <div style={cell}>
                  <p style={label}>{t('Staking')}</p>
                  <p style={text}>
                    {parseInt(formatEther(item.stake))} {t('Pose')}
                  </p>
                </div>
                <div style={cell}>
                  <p style={label}>{t('Team staking')}</p>
                  <p style={text}>
                    {parseInt(formatEther(BigNumber.from(item.stake1 ?? 0).add(BigNumber.from(item.stake2 ?? 0))))}{' '}
                    {t('Pose')}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
        <div style={pagination(isMobile)}>
          <span style={text}>
            <span
              style={currentPage === 0 ? textDisable : textBtn}
              onClick={() => goPage(currentPage - 1)}
              role="button"
              tabIndex={0}
              onKeyDown={undefined}
            >
              {`< `}
            </span>
            {totalPage &&
              [...Array(totalPage).keys()].map((i) => (
                <Fragment key={i}>
                  <span
                    style={i === currentPage ? textDisable : textBtn}
                    onClick={() => goPage(i)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={undefined}
                  >
                    {i + 1}
                  </span>
                  {i + 1 < totalPage && ','}
                </Fragment>
              ))}
            <span
              style={totalPage - 1 === currentPage ? textDisable : textBtn}
              onClick={() => goPage(currentPage + 1)}
              role="button"
              tabIndex={0}
              onKeyDown={undefined}
            >
              {` >`}
            </span>
          </span>
        </div>
      </div>
      <div style={rightStyle(isMobile)}>
        <div style={headerTitle}>
          <Image style={{ width: 35 }} src={ReferralIcon} alt="referral" />
          <span style={title(isMobile)}>{t('Your Referral')}</span>
        </div>
        <p style={subTitle(isMobile)}>{t('Referral Link')}</p>
        <div style={isMobile ? wrapInputMobile : wrapInput}>
          <input placeholder="" value={referralLink} style={input(isMobile)} disabled />
          <CopyToClipboard text={referralLink}>
            <Button width="125px" variant="success" height="45px">
              {t('Copy')}
            </Button>
          </CopyToClipboard>
        </div>
        {isMobile ? <CustomDivideMobile src={Divide} alt="divide" /> : <CustomDivide src={Divide} alt="divide" />}
        {isTrident ? (
          <div style={referralStyle}>
            <div style={referralLeft}>
              <span style={referralLabel}>{t('Level')}</span>
            </div>
            <div style={angelView}>
              <Image style={LevelImg} src={Trident} alt="trident" />
              <span style={referralText}>{t('Trident')}</span>
            </div>
          </div>
        ) : person?.isAngel ? (
          <div style={referralStyle}>
            <div style={referralLeft}>
              <span style={referralLabel}>{t('Level')}</span>
            </div>
            <div style={angelView}>
              <Image style={LevelImg} src={Angel} alt="angel" />
              <span style={referralText}>{t('Angel')}</span>
            </div>
          </div>
        ) : null}
        <div style={referralStyle}>
          <div style={referralLeft}>
            <span style={referralLabel}>{t('Current Referral')}</span>
          </div>
          <span style={referralText}>{inviteeRecordsLength?.toString() || '0'}</span>
        </div>
        <div style={referralStyle}>
          <div style={referralLeft}>
            <span style={referralLabel}>{t('Referral Staked')}</span>
          </div>
          <span style={referralText}>
            {formatBigNumber(ethers.BigNumber.from(referralStakingAmount || ethers.constants.Zero), 4)} POSE
          </span>
        </div>{' '}
        <div style={referralStyle}>
          <div style={referralLeft}>
            <span style={referralLabel}>{t('Total Referral Earned')}</span>
          </div>
          <span style={referralText}>
            {formatBigNumber(
              person ? person.accumulativeBonus1.add(person.accumulativeBonus2) : ethers.constants.Zero,
              4,
            )}{' '}
            POSE
          </span>
        </div>
        <div style={isMobile ? rewardHeaderTitleMobile : rewardHeaderTitle}>
          <Image style={{ width: 35 }} src={RewardIcon} alt="reward" />
          <span style={title(isMobile)}>{t('Your Reward')}</span>
        </div>
        <div style={isMobile ? wrapRankUpdateTimeMobile : wrapRankUpdateTime}>
          <div style={textXl}>
            {t('Week')} {weekIndex} {t('Rank')}
          </div>
          <div style={textC8}>
            {t('Update time: ')} {updateTime}
          </div>
        </div>
        <div style={isMobile ? yourRankingRowMobile : yourRankingRow}>
          <div style={isMobile ? yourRankingCellMobile : yourRankingFirstCell}>
            <div style={textLg}>{t('Your Ranking')}</div>
            {yourWeeklyRank ? (
              <div style={textSuccess}>
                {yourWeeklyRank?.rank}
                {yourWeeklyRank?.rank === 1 ? 'st' : yourWeeklyRank?.rank === 2 ? 'nd' : 'th'}
              </div>
            ) : (
              <div style={textSuccess}>{`> ${weeklyRank?.length ?? 21}th`}</div>
            )}
          </div>
          <div style={isMobile ? yourRankingCellMobile : yourRankingCell}>
            <div style={textLg}>{t('Estimate Rewards')}</div>
            <div style={textSuccess}>{parseInt(formatEther(estimateRewards))} Pose</div>
          </div>
          <div style={isMobile ? yourRankingCellMobile : yourRankingCell}>
            <div style={textLg}>{t('Release Time')}</div>
            <div style={textXl}>{releaseTime}</div>
          </div>
        </div>
        {isMobile ? <RewardDivideMobile src={Divide} alt="divide" /> : <RewardDivide src={Divide} alt="divide" />}
        {showRewardBottom && (
          <div style={isMobile ? claimStyleMobile : claimStyle}>
            <div style={claimLeft}>
              <div style={claimCell}>
                <div style={referralText}>{t('Unclaim Reward')}</div>
                <div style={referralText}>{parseInt(formatEther(unclaimReward))} Pose</div>
              </div>
              <div style={claimCell}>
                <div style={referralText}>{t('Totally Claimed')}</div>
                <div style={referralText}>{parseInt(formatEther(totallyClaimed))} Pose</div>
              </div>
            </div>
            {unclaimReward.gt(0) && (
              <div>
                <Button
                  width="125px"
                  variant="success"
                  height="45px"
                  isLoading={withdrawPoseLoading}
                  onClick={handleClaimPose}
                >
                  {t('Claim')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default WeeklyRank

const layout: React.CSSProperties = {
  marginTop: 70,
  display: 'flex',
  flexDirection: 'row',
}
const layoutMobile: React.CSSProperties = {
  marginTop: 40,
  display: 'flex',
  flexDirection: 'column-reverse',
}
const title = (isMobile: boolean) => ({ fontSize: 21, fontWeight: 700, marginLeft: 10 })
const subTitle = (isMobile: boolean) => ({ fontSize: 18, fontWeight: 400, marginTop: 30 })
const leftStyle = (isMobile: boolean) => ({ width: isMobile ? '100%' : '55%', display: 'none' })
const rightStyle = (isMobile: boolean) => ({ width: isMobile ? '100%' : '100%' })
const table: React.CSSProperties = { marginTop: 40 }
const text: React.CSSProperties = { fontSize: 14, fontWeight: 400 }
const textC8: React.CSSProperties = { fontSize: 14, fontWeight: 400, color: '#C8C8C8' }
const textBtn: React.CSSProperties = { fontSize: 14, fontWeight: 400, cursor: 'pointer' }
const textDisable: React.CSSProperties = { fontSize: 14, fontWeight: 400, cursor: 'default', color: '#C5C5C5' }
const textBold: React.CSSProperties = { fontSize: 14, fontWeight: 700 }
const textLg: React.CSSProperties = { fontSize: 16, fontWeight: 500, color: '#C8C8C8' }
const textXl: React.CSSProperties = { fontSize: 21, fontWeight: 500, color: '#FFFFFF' }
const textSuccess: React.CSSProperties = { fontSize: 21, fontWeight: 500, color: '#15D4B1' }
const stt: React.CSSProperties = {
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  fontSize: 16,
  fontWeight: 700,
}
const rowStyle = (isMobile: boolean) => ({
  display: 'flex',
  background:
    'linear-gradient(268.71deg, rgba(145, 234, 228, 0.8) 0.26%, rgba(134, 168, 231, 0.8) 48.87%, rgba(127, 127, 213, 0.8) 99.54%)',
  width: isMobile ? '100%' : '80%',
  borderRadius: 10,
  padding: 15,
  marginBottom: 5,
  justifyContent: 'space-between',
})
const cell: React.CSSProperties = {
  width: '25%',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  display: 'flex',
}
const pagination = (isMobile: boolean) => ({
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  width: isMobile ? '100%' : '80%',
  marginTop: 15,
})
const headerTitle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
}
const rewardHeaderTitleMobile: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 40,
  marginBottom: 20,
}
const rewardHeaderTitle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 40,
  marginBottom: 20,
}
const angelView: React.CSSProperties = {
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  gap: 10,
}
const headerTitleMobile: React.CSSProperties = {
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 50,
}
const label: React.CSSProperties = {
  fontSize: 12,
  color: '#C5C5C5',
  fontWeight: 400,
  marginBottom: 15,
}
const referralStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  marginTop: 30,
  alignItems: 'center',
  justifyContent: 'space-between',
}
const referralLeft: React.CSSProperties = {}
const referralLabel: React.CSSProperties = {
  fontSize: 18,
  fontWeight: 500,
  minWidth: 300,
}
const referralText: React.CSSProperties = {
  fontSize: 18,
  fontWeight: 500,
}
const wrapInput: React.CSSProperties = {
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
  marginTop: 15,
  gap: 30,
}
const wrapInputMobile: React.CSSProperties = {
  display: 'flex',
  marginTop: 15,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 30,
}
const wrapRankUpdateTime: React.CSSProperties = {
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
  marginTop: 15,
  marginBottom: 27,
  gap: 30,
}
const wrapRankUpdateTimeMobile: React.CSSProperties = {
  display: 'flex',
  marginTop: 15,
  marginBottom: 27,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 30,
}
const yourRankingRowMobile: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 16,
}
const yourRankingRow: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}
const yourRankingCellMobile: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: 11,
}
const yourRankingFirstCell: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  minWidth: '104px',
  gap: 11,
}
const yourRankingCell: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: 11,
}
const claimStyleMobile: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 24,
  marginTop: 43,
}
const claimStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: 24,
  marginTop: 43,
}
const claimLeft: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 30,
}
const claimCell: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
}
const input = (isMobile: boolean) => ({
  fontSize: 16,
  fontWeight: 400,
  padding: 10,
  backgroundColor: 'transparent',
  borderRadius: '1em',
  width: isMobile ? '100%' : '70%',
  borderImageSource: 'linear-gradient(180deg, #7F7FD5 0%, #86A8E7 54.17%, #91EAE4 100%)',
  borderWidth: 1,
  borderImageSlice: 1,
})

const CustomDivide = styled(Image)`
  width: 100%;
  margin-top: 30px;
`
const CustomDivideMobile = styled(Image)`
  width: 100%;
  margin-top: 30px;
`
const RewardDivide = styled(Image)`
  width: 100%;
  margin-top: 32px;
`
const RewardDivideMobile = styled(Image)`
  width: 100%;
  margin-top: 16px;
`

const WeeklyRankDesc: React.CSSProperties = {
  marginTop: 20,
}

const LevelImg: React.CSSProperties = {
  width: 25,
}
