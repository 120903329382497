import { ethers } from 'ethers'
import { useTranslation } from '@pancakeswap/localization'
import Image from 'next/image'
import { Button, useToast } from "@pancakeswap/uikit";
import useResponsive from 'hooks/useResponsive'
import { PoseStakingContext } from 'views/PoseStaking/context'
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import usePosePoolContract from 'views/PoseStaking/hooks/usePosePoolContract'
import { formatBigNumber } from '@pancakeswap/utils/formatBalance'
import { parseEther } from "@ethersproject/units";
import { useBlockNumber } from "wagmi";
import Countdown from "react-countdown";
import StakingPoolImage from '../../pngs/staking_pool_image.png'
import usePoseBalance from "../../hooks/usePoseBalance";

const StakingPool = () => {
  const { t } = useTranslation()
  const { isMobile } = useResponsive()
  const { listSingleEntries } = useContext(PoseStakingContext)
  const { balance, getPoseAllowance, ngBalance, getPoseNgAllowance } = usePoseBalance();
  const {
    apy,
    config,
    approve,
    stakeNg,
    approvePoseNg,
    stakeNgLoading,
    approveLoading
  } = usePosePoolContract(
    listSingleEntries && listSingleEntries.PosePool.address,
  )

  const [amount, setAmount] = useState<number>(0)
  const [isOldEnoughAllowance, setIsOldEnoughAllowance] = useState<boolean>(false)
  const [isNgEnoughAllowance, setIsNgEnoughAllowance] = useState<boolean>(false)
  const { data: blockNumber } = useBlockNumber({ watch: true })
  const { toastError } = useToast()

  const insufficientOldBalance = useMemo(() => balance?.lt(parseEther(amount.toString())), [balance, amount])
  const insufficientBalance = useMemo(() => ngBalance?.lt(parseEther(amount.toString())), [ngBalance, amount])

  useEffect(() => {
    if (listSingleEntries) {
      const checkAllow = async () => {
        const allowance = await getPoseAllowance(listSingleEntries.PosePool.address)
        const ngAllowance = await getPoseNgAllowance(listSingleEntries.PosePool.address)
        setIsOldEnoughAllowance(allowance.gte(parseEther(amount.toString())))
        setIsNgEnoughAllowance(ngAllowance.gte(parseEther(amount.toString())))
      }
      checkAllow()
    }
  }, [getPoseAllowance, getPoseNgAllowance, listSingleEntries, amount, blockNumber])

  const renderOldBtnTitle = useMemo(() => {
    if (!isOldEnoughAllowance) {
      return t('Approve(legacy)')
    }
    if (config?.startTime && config.startTime.mul(1000).gt(Date.now())) {
      return <Countdown date={config.startTime.mul(1000).toNumber()} daysInHours />
    }
    return t('Stake(legacy)')
  }, [isOldEnoughAllowance, t, config])

  const renderNgBtnTitle = useMemo(() => {
    if (!isNgEnoughAllowance) {
      return t('Approve(pose)')
    }
    if (config?.startTime && config.startTime.mul(1000).gt(Date.now())) {
      return <Countdown date={config.startTime.mul(1000).toNumber()} daysInHours />
    }
    return t('Stake(pose)')
  }, [isNgEnoughAllowance, t, config])

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value || Number(event.target.value) < 0) {
      setAmount(0)
      return
    }
    setAmount(Number(event.target.value))
  }

  const onClickStake = async () => {
    if (!(listSingleEntries && stakeNg && approve && config)) {
      return
    }
    if (!isOldEnoughAllowance) {
      await approve()
      return
    }
    if (insufficientOldBalance) {
      toastError(t('Insufficient legacy Balance'))
      return
    }
    if (!amount) {
      toastError(t('Please enter amount'))
      return
    }
    await stakeNg(parseEther(amount.toString()), ethers.constants.Zero)
  }

  const onClickStakeNg = async () => {
    if (!(listSingleEntries && stakeNg && approvePoseNg && config)) {
      return
    }
    if (!isNgEnoughAllowance) {
      await approvePoseNg()
      return
    }
    if (config.startTime.mul(1000).gt(Date.now())) {
      toastError(t('Staking pool is not open yet'))
      return
    }
    if (insufficientBalance) {
      toastError(t('Insufficient POSE Balance'))
      return
    }
    if (!amount) {
      toastError(t('Please enter amount'))
      return
    }
    await stakeNg(ethers.constants.Zero, parseEther(amount.toString()))
  }

  return (
    <div style={isMobile ? layoutMobile : layout}>
      <Image src={StakingPoolImage} alt="staking-pool" />
      <p style={isMobile ? titleMobile : title}>{t('Staking Pool')}</p>
      {isMobile ? (
        <div style={boxMobile}>
          <div style={itemInPool}>
            <p style={normalText}>{t('APY')}</p>
            <p style={activeText}>{apy ? `${formatBigNumber(apy, 2)}%` : '-'}</p>
          </div>
        </div>
      ) : (
        <>
          <div style={itemInPool}>
            <p style={normalText}>{t('APY')}</p>
            <p style={activeText}>{apy ? `${formatBigNumber(apy, 2)}%` : '-'}</p>
          </div>
        </>
      )}

      {!isMobile && (
        <div
          style={{
            background: '#15D4B1',
            width: '1px',
            height: '70px',
          }}
        />
      )}
      <div style={isMobile ? right2Mobile : right2}>
        <div style={itemInPool}>
          <p style={normalText}>{t('Legacy Wallet')}</p>
          <p style={activeText}>{formatBigNumber(balance || ethers.constants.Zero, 2)}</p>
        </div>
        <div style={itemInPool}>
          <p style={normalText}>{t('Pose Wallet')}</p>
          <p style={activeText}>{formatBigNumber(ngBalance || ethers.constants.Zero, 2)}</p>
        </div>
        <input
          name="amount"
          type="number"
          value={amount.toString()}
          onChange={handleAmountChange}
          style={isMobile ? inputStyleMobile : inputStyle}
          placeholder={t('Type the amount')}
        />
        <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
          <Button
            isLoading={stakeNgLoading || approveLoading}
            disabled={stakeNgLoading || approveLoading}
            onClick={onClickStake}
            width="120px"
            variant="success"
            scale="sm"
            height="28px"
            minHeight={28}
          >
            {renderOldBtnTitle}
          </Button>
          <Button
            isLoading={stakeNgLoading || approveLoading}
            disabled={stakeNgLoading || approveLoading}
            onClick={onClickStakeNg}
            width="120px"
            variant="success"
            scale="sm"
            height="28px"
            minHeight={28}
          >
            {renderNgBtnTitle}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default StakingPool


const layout: React.CSSProperties = {
  // marginTop: 70,
  display: 'flex',
  border: '1px solid #15D4B1',
  padding: '30px 30px',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const layoutMobile: React.CSSProperties = {
  marginTop: 20,
  display: 'flex',
  border: '1px solid #15D4B1',
  padding: '30px 70px',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
}
const itemInPool: React.CSSProperties = {
  lineHeight: 1.8,
  textAlign: 'center',
  // minWidth: '100px',
}
const normalText: React.CSSProperties = { fontSize: 18, fontWeight: 400 }
const boxMobile: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  marginTop: 20,
}
const title: React.CSSProperties = { fontSize: 18, fontWeight: 700 }
const titleMobile: React.CSSProperties = { fontSize: 18, fontWeight: 700, marginTop: 20 }
const activeText: React.CSSProperties = { fontSize: 18, fontWeight: 700, color: '#15D4B1' }
const right2: React.CSSProperties = {
  width: '60%',
  display: 'flex',
  justifyContent: 'space-between',
  columnGap: 20,
  alignItems: 'center',
  paddingLeft: 4,
}
const right2Mobile: React.CSSProperties = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  rowGap: 8,
  alignItems: 'center',
  flexDirection: 'column',
}
const inputStyle: React.CSSProperties = {
  fontSize: 16,
  fontWeight: 400,
  color: '#8B8B8B',
  border: '1px solid #8B8B8B',
  padding: '20px 30px',
  borderRadius: 10,
  background: 'transparent',
  width: '60%',
}
const inputStyleMobile: React.CSSProperties = {
  fontSize: 16,
  fontWeight: 400,
  color: '#8B8B8B',
  border: '1px solid #8B8B8B',
  padding: 15,
  borderRadius: 10,
  background: 'transparent',
  marginBottom: 20,
  width: '100%',
}
