import useSWR from "swr";
import { REFERRAL_STAKING_API } from "../../../config/constants/endpoints";

const fetchWithdrawTickets = async (address: string) => {
  const response = await fetch(`${REFERRAL_STAKING_API}/withdraw-tickets?address=${address}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  const result = await response.json()
  return result as {
    claimed: string; tickets: {
      id: number;
      rank: number;
      stakes: string;
      who: string;
      poseAmount: string;
      ticketNumber: string;
      signature: string;
      withdrawn: string;
      date: number;
    }[]
  };
}

export const useWithdrawTickets = (address: string) => {
  return useSWR(['withdraw-tickets', address], () => {
    return fetchWithdrawTickets(address);
  })
}
