import { useState, useEffect } from 'react'
import { getNameServiceContract } from 'utils/contractHelpers'
import { parseBytes32String } from 'ethers/lib/utils'
import { IListSingleEntries } from '../context'

const useNameServiceContract = () => {
  const [listSingleEntries, setListSingleEntries] = useState<IListSingleEntries>(null)

  useEffect(() => {
    const fetchListSingleEntries = async () => {
      const nameServiceContract = getNameServiceContract()
      const response = await nameServiceContract.listSingleEntries()
      if (response) {
        const convertData = response.reduce(
          (result, item) => ({
            ...result,
            [parseBytes32String(item[0])]: {
              address: item[1],
              name: item[0],
            },
          }),
          {},
        )

        setListSingleEntries(convertData)
      }
    }
    fetchListSingleEntries()
  }, [])
  return { listSingleEntries }
}

export default useNameServiceContract
