import { ethers } from 'ethers'
import { BigNumber } from '@ethersproject/bignumber'
import { useState, useEffect, useCallback } from 'react'
import { getContract } from 'utils/contractHelpers';
import { useAccount, useBlockNumber } from 'wagmi';
import Erc20 from 'config/abi/erc20.json';
import { bscTokens } from '@pancakeswap/tokens';

const useUsdtOnBscBalance = () => {
  const { address: account } = useAccount()
  const [usdtBalance, setUsdtBalance] = useState<BigNumber>(ethers.constants.Zero)
  const { data: blockNumber } = useBlockNumber({watch: true})

  const getUsdtBalance = useCallback(async () => {
    const contract = getContract({
      abi: Erc20,
      address: bscTokens?.usdt?.address,
    })
    if (account && contract) {
      const rawBalance = await contract.balanceOf(account)
      setUsdtBalance(rawBalance)
      return
    }
    setUsdtBalance(ethers.constants.Zero)
  }, [account])

  useEffect(() => {
    getUsdtBalance()
  }, [getUsdtBalance, blockNumber])

  const getUsdtAllowance = useCallback(
    async (contractAddress: string) => {
      const contract = getContract({
        abi: Erc20,
        address: bscTokens?.usdt?.address,
      })
      if (contract && account && contractAddress) {
        const allowance = await contract.allowance(account, contractAddress)
        return allowance as BigNumber
      }
      return ethers.constants.Zero
    },
    [account],
  )

  return { usdtBalance, getUsdtAllowance }
}

export default useUsdtOnBscBalance
