import { ethers } from 'ethers'
import { BigNumber } from '@ethersproject/bignumber'
import { useToast } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { useCallback, useMemo } from 'react'
import { getContract, getInvitationCenterContract, getPoseContract, getPosePoolContract } from 'utils/contractHelpers';
import { useAccount, useSigner } from 'wagmi'
import useCatchTxError from 'hooks/useCatchTxError'
import { formatBytes32String } from 'ethers/lib/utils'
import useSWR from 'swr'
import { multicallv3 } from 'utils/multicall'
import InvitationCenterInterface from 'config/abi/InvitationCenterInterface.json'
import PosePoolInterface from 'config/abi/PosePoolInterface.json'
import Erc20 from 'config/abi/erc20.json';
import { bscTokens } from '@pancakeswap/tokens';
import { PoseUsdtPriceParamStruct, SignatureStruct, WithdrawPoseParamStruct } from 'config/abi/types/PosePoolInterface';
import usePoseBalance from './usePoseBalance'
import { IBill } from '../context'
import useNameServiceContract from './useNameServiceContract'
import useUsdtOnBscBalance from './useUsdtOnBscBalance';

const REFRESH_INTERVAL = 5000

const usePosePoolContract = (contractAddress: string) => {
  const { address: account } = useAccount()
  const { data: signer } = useSigner()
  const { balance, ngBalance } = usePoseBalance()
  const { usdtBalance } = useUsdtOnBscBalance()
  const { fetchWithCatchTxError: fetchStake, loading: stakeLoading } = useCatchTxError()
  const { fetchWithCatchTxError: fetchStakeNg, loading: stakeNgLoading } = useCatchTxError()
  const { fetchWithCatchTxError: fetchApprove, loading: approveLoading } = useCatchTxError()
  const { fetchWithCatchTxError: fetchWithdraw, loading: withdrawLoading } = useCatchTxError()
  const { fetchWithCatchTxError: fetchWithdrawComplete, loading: withdrawCompleteLoading } = useCatchTxError()
  const { fetchWithCatchTxError: fetchCompound, loading: compoundLoading } = useCatchTxError()
  const { fetchWithCatchTxError: fetchWithdrawPose, loading: withdrawPoseLoading } = useCatchTxError()
  const { fetchWithCatchTxError: fetchGetRewardAndBonus, loading: getRewardAndBonusLoading } = useCatchTxError()
  const { fetchWithCatchTxError: fetchLinearUnlockNg, loading: linearUnlockNgLoading } = useCatchTxError()
  const { fetchWithCatchTxError: fetchWithdrawMonthlyNg, loading: withdrawMonthlyNgLoading } = useCatchTxError()
  const { fetchWithCatchTxError: fetchMigrateBill, loading: migrateBillLoading } = useCatchTxError()
  const { fetchWithCatchTxError: fetchWithdrawCloseOld, loading: withdrawCloseOldLoading } = useCatchTxError()
  const { toastError } = useToast()
  const { t } = useTranslation()
  const { listSingleEntries } = useNameServiceContract()
  const InvitationCenterAddress = listSingleEntries?.InvitationCenter?.address

  const { data: config } = useSWR(
    ['getConfig', contractAddress],
    async () => {
      const contract = getPosePoolContract(contractAddress)
      const res = await contract.getConfig()
      return res
    },
    { refreshInterval: REFRESH_INTERVAL },
  )

  const { data: person } = useSWR(
    ['getPerson', contractAddress, account],
    async () => {
      const contract = getPosePoolContract(contractAddress)
      const res = await contract.getPerson(account)
      return res
    },
    { refreshInterval: REFRESH_INTERVAL },
  )

  const { data: totalReward } = useSWR(
    ['getRewardPosePool', account, contractAddress],
    async () => {
      const contract = getPosePoolContract(contractAddress)
      const data = await contract.callStatic.getRewardAndBonus(formatBytes32String(''), { from: account })
      return data
    },
    { refreshInterval: REFRESH_INTERVAL },
  )

  const { data: referralStakedSourceAmount, error } = useSWR(
    ['referralStakedSourceAmount', account, contractAddress, InvitationCenterAddress],
    async () => {
      const invitationCenterContract = getInvitationCenterContract(InvitationCenterAddress)

      const inviteeRecordsLength = await invitationCenterContract.inviteeRecordsLength(account)
      const inviteeRecordCall = [...Array(inviteeRecordsLength.toNumber()).keys()].map((index) => ({
        abi: InvitationCenterInterface.abi,
        address: InvitationCenterAddress,
        name: 'inviteeRecordsAt',
        params: [account, index],
      }))
      const inviteeRecordRes = await multicallv3({ calls: [...inviteeRecordCall] })
      const personCall = inviteeRecordRes.flat().map((item) => ({
        abi: PosePoolInterface.abi,
        address: contractAddress,
        name: 'getPerson',
        params: [item],
      }))
      const personRes = await multicallv3({ calls: [...personCall] })
      const totalStakedSourceAmount = personRes
        .flat()
        .reduce((acc, item) => acc.add(item.stakedSourceAmount), ethers.constants.Zero)
      return totalStakedSourceAmount
    },
    { refreshInterval: REFRESH_INTERVAL },
  )

  const getActiveBills = {
    name: 'getActiveBills',
    params: [account],
    abi: PosePoolInterface.abi,
    address: contractAddress,
    signer,
  }
  const getWithdrawingBills = {
    name: 'getWithdrawingBills',
    params: [account],
    abi: PosePoolInterface.abi,
    address: contractAddress,
    signer,
  }
  const getWithdrawnBills = {
    name: 'getWithdrawnBills',
    params: [account],
    abi: PosePoolInterface.abi,
    address: contractAddress,
    signer,
  }

  const getNgActiveBills = {
    name: 'getNgActiveBills',
    params: [account],
    abi: PosePoolInterface.abi,
    address: contractAddress,
    signer,
  }

  const getNgWithdrawnBills = {
    name: 'getNgWithdrawnBills',
    params: [account],
    abi: PosePoolInterface.abi,
    address: contractAddress,
    signer,
  }

  const { data: bills } = useSWR(
    ['getBills', contractAddress, account],
    async () => {
      const billIds = await multicallv3({
        calls: [getActiveBills, getWithdrawingBills, getWithdrawnBills],
      })
      const concatBills = billIds.flat().flat()
      const getDetailsBills = concatBills.map((billId) => ({
        abi: PosePoolInterface.abi,
        address: contractAddress,
        name: 'getBill',
        params: [billId],
        signer,
      }))
      const detailBills = await multicallv3({
        calls: getDetailsBills,
      })
      const detailBillsWithId: IBill[] = detailBills.flat().map((item, index) => ({ ...item, id: concatBills[index] }))
      return detailBillsWithId
    },
    { refreshInterval: REFRESH_INTERVAL },
  )

  const { data: ngBills } = useSWR(
    ['getNgBills', contractAddress, account],
    async () => {
      const billIds = await multicallv3({
        calls: [getNgActiveBills, getNgWithdrawnBills],
      })
      const concatBills = billIds.flat().flat()
      const getDetailsBills = concatBills.map((billId) => ({
        abi: PosePoolInterface.abi,
        address: contractAddress,
        name: 'getBill',
        params: [billId],
        signer,
      }))
      const detailBills = await multicallv3({
        calls: getDetailsBills,
      })
      const detailBillsWithId: IBill[] = detailBills.flat().map((item, index) => ({ ...item, id: concatBills[index] }))
      return detailBillsWithId
    },
    { refreshInterval: REFRESH_INTERVAL },
  )

  const stake = useCallback(
    async (amount: BigNumber, inviterCode: string = formatBytes32String('')) => {
      if (!(signer && contractAddress && balance && amount)) {
        return
      }
      if (amount.eq(ethers.constants.Zero)) {
        return
      }
      if (amount.gt(balance)) {
        toastError(t('Error'), t('Not enough POSE'))
        return
      }
      const contract = getPosePoolContract(contractAddress, signer)
      await fetchStake(async () => {
        const gasEstimate = await contract.estimateGas.stake(amount, inviterCode)
        const gasLimit = gasEstimate.add(gasEstimate.div(5));
        return contract.stake(amount, inviterCode, {gasLimit})
      })
    },
    [contractAddress, signer, balance, fetchStake, t, toastError],
  )

  const stakeNg = useCallback(
    async (poseAmount: BigNumber, poseNgAmount: BigNumber, inviterCode: string = formatBytes32String('')) => {
      if (!(signer && contractAddress && balance && (poseAmount || poseNgAmount))) {
        return
      }
      if (poseAmount?.eq(ethers.constants.Zero) && poseNgAmount?.eq(ethers.constants.Zero)) {
        return
      }
      if (poseAmount?.gt(balance)) {
        toastError(t('Error'), t('Not enough POSE'))
        return
      }
      if (poseNgAmount?.gt(ngBalance)) {
        toastError(t('Error'), t('Not enough POSE NG'))
        return
      }
      const contract = getPosePoolContract(contractAddress, signer)
      await fetchStakeNg(async () => {
        const gasEstimate = await contract.estimateGas.stakeNg(poseAmount ?? ethers.constants.Zero, poseNgAmount ?? ethers.constants.Zero, inviterCode)
        const gasLimit = gasEstimate.add(gasEstimate.div(5));
        return contract.stakeNg(poseAmount ?? ethers.constants.Zero, poseNgAmount ?? ethers.constants.Zero, inviterCode, {gasLimit})
      })
    },
    [contractAddress, signer, balance, ngBalance, fetchStakeNg, t, toastError],
  )

  const stakeUsdt = useCallback(
    async (param: PoseUsdtPriceParamStruct, signature: SignatureStruct, amount: BigNumber, inviterCode: string = formatBytes32String("")) => {
      if (!(signer && contractAddress && usdtBalance && amount)) {
        return
      }
      if (amount.eq(ethers.constants.Zero)) {
        return
      }
      if (amount.gt(usdtBalance)) {
        toastError(t('Error'), t('Not enough USDT'))
        return
      }
      const contract = getPosePoolContract(contractAddress, signer)
      await fetchStake(async () => {
        const gasEstimate = await contract.estimateGas.stakeUsdt(param, signature, amount, inviterCode)
        const gasLimit = gasEstimate.add(gasEstimate.div(5));


        return contract.stakeUsdt(param, signature, amount, inviterCode, {gasLimit})
      })
    },
    [contractAddress, signer, usdtBalance, fetchStake, t, toastError],
  )

  const approve = useCallback(async () => {
    if (!(signer && contractAddress)) {
      return
    }
    const poseContract = getPoseContract(signer)
    await fetchApprove(() => {
      return poseContract.approve(contractAddress, ethers.constants.MaxUint256)
    })
  }, [contractAddress, fetchApprove, signer])

  const approveUsdt = useCallback(async () => {
    if (!(signer && contractAddress)) {
      return
    }
    const usdtContract = getContract({
      abi: Erc20,
      address: bscTokens?.usdt?.address,
      signer,
    })
    await fetchApprove(() => {
      return usdtContract.approve(contractAddress, ethers.constants.MaxUint256)
    })
  }, [contractAddress, fetchApprove, signer])

  const approvePoseNg = useCallback(async () => {
    if (!(signer && contractAddress && listSingleEntries)) {
      return
    }
    const poseNgContract = getContract({
      abi: Erc20,
      address: listSingleEntries?.PoseNg?.address,
      signer,
    })
    await fetchApprove(() => {
      return poseNgContract.approve(contractAddress, ethers.constants.MaxUint256)
    })
  }, [contractAddress, listSingleEntries, fetchApprove, signer])

  const withdraw = useCallback(
    async (billIds: any[], inviterCode: string = formatBytes32String('')) => {
      if (!(signer && contractAddress && billIds.length > 0)) {
        return
      }
      const contract = getPosePoolContract(contractAddress, signer)
      await fetchWithdraw(() => {
        return contract.withdraw(billIds, inviterCode)
      })
    },
    [contractAddress, fetchWithdraw, signer],
  )

  const withdrawComplete = useCallback(
    async (billIds: any[], inviterCode: string = formatBytes32String('')) => {
      if (!(signer && contractAddress && billIds.length > 0)) {
        return
      }
      const contract = getPosePoolContract(contractAddress, signer)
      await fetchWithdrawComplete(() => {
        return contract.withdrawComplete(billIds, inviterCode)
      })
    },
    [contractAddress, fetchWithdrawComplete, signer],
  )

  const compound = useCallback(
    async (inviterCode: string = formatBytes32String('')) => {
      if (!(signer && contractAddress)) {
        return
      }
      const contract = getPosePoolContract(contractAddress, signer)
      await fetchCompound(() => {
        return contract.compound(inviterCode)
      })
    },
    [contractAddress, fetchCompound, signer],
  )

  const withdrawPose = useCallback(
    async (param: WithdrawPoseParamStruct, signature: SignatureStruct) => {
      const contract = getPosePoolContract(contractAddress, signer)
      await fetchWithdrawPose(() => {
        return contract.withdrawPose(param, signature)
      })
    },
    [contractAddress, fetchWithdrawPose, signer],
  )

  const getRewardAndBonus = useCallback(
    async (inviterCode: string = formatBytes32String('')) => {
      if (!(signer && contractAddress)) {
        return
      }
      const contract = getPosePoolContract(contractAddress, signer)
      await fetchGetRewardAndBonus(() => {
        return contract.getRewardAndBonus(inviterCode)
      })
    },
    [contractAddress, fetchGetRewardAndBonus, signer],
  )

  const linearUnlockNg = useCallback(
    async (inviterCode: string = formatBytes32String('')) => {
      if (!(signer && contractAddress)) {
        return
      }
      const contract = getPosePoolContract(contractAddress, signer)
      await fetchLinearUnlockNg(() => {
        return contract.linearUnlockNg(inviterCode)
      })
    },
    [contractAddress, fetchLinearUnlockNg, signer],
  )

  const withdrawMonthlyNg = useCallback(
    async (billIds: any[], inviterCode: string = formatBytes32String('')) => {
      if (!(signer && contractAddress && billIds?.length > 0)) {
        return
      }
      const contract = getPosePoolContract(contractAddress, signer)
      await fetchWithdrawMonthlyNg(() => {
        return contract.withdrawMonthlyNg(billIds, inviterCode)
      })
    },
    [contractAddress, fetchWithdrawMonthlyNg, signer],
  )

  const migrateBill = useCallback(
    async (inviterCode: string = formatBytes32String('')) => {
      if (!(signer && contractAddress)) {
        return
      }
      const contract = getPosePoolContract(contractAddress, signer)
      await fetchMigrateBill(() => {
        return contract.migrateBill(inviterCode)
      })
    },
    [contractAddress, fetchMigrateBill, signer],
  )

  const withdrawCloseOld = useCallback(
    async (inviterCode: string = formatBytes32String('')) => {
      if (!(signer && contractAddress)) {
        return
      }
      const contract = getPosePoolContract(contractAddress, signer)
      await fetchWithdrawCloseOld(() => {
        return contract.withdrawCloseOld(inviterCode)
      })
    },
    [contractAddress, fetchWithdrawCloseOld, signer],
  )

  const staticLinearUnlockNg = useCallback(
    async (inviterCode: string = formatBytes32String('')) => {
      const contract = getPosePoolContract(contractAddress)
      const data = await contract.callStatic.linearUnlockNg(inviterCode, { from: account })
      return data
    },
    [contractAddress],
  )

  // amplify 1e18 in 100
  const staticWithdrawMonthlyNg = useCallback(
    async (billIds: any[], inviterCode: string = formatBytes32String('')) => {
      const contract = getPosePoolContract(contractAddress)
      const data = await contract.callStatic.withdrawMonthlyNg(billIds, inviterCode, { from: account })
      return data
    },
    [contractAddress],
  )

  // amplify 1e18 in 100
  const apy = BigNumber.from('100000000000000000000')

  return {
    config,
    person,
    bills,
    ngBills,
    stake,
    stakeNg,
    stakeNgLoading,
    stakeUsdt,
    stakeLoading,
    approve,
    approveUsdt,
    approvePoseNg,
    approveLoading,
    withdraw,
    withdrawLoading,
    withdrawComplete,
    withdrawCompleteLoading,
    compound,
    compoundLoading,
    withdrawPose,
    withdrawPoseLoading,
    withdrawCloseOld,
    withdrawCloseOldLoading,
    getRewardAndBonus,
    getRewardAndBonusLoading,
    linearUnlockNg,
    linearUnlockNgLoading,
    withdrawMonthlyNg,
    withdrawMonthlyNgLoading,
    staticLinearUnlockNg,
    staticWithdrawMonthlyNg,
    migrateBill,
    migrateBillLoading,
    totalReward,
    apy,
    referralStakedSourceAmount,
  }
}

export default usePosePoolContract
